import { default as _axios } from "axios";

let baseURL = `https://api.${window.location.hostname}/`;

if (process.env.REACT_APP_ENV === 'development') {
  baseURL = "https://api.test.1mrobotics.dev"
}

const axiosApiInstance = _axios.create({
  baseURL: baseURL,
});

export const axios = axiosApiInstance;
